export default class WorksiteClosureReportRoute {
    protected _worksite: string;

    constructor(worksite: string) {
        this._worksite = worksite;
    }

    get worksite() {
        return this._worksite;
    }

    set worksite(value: string) {
        this.worksite = value;
    }

    base(): string {
        if (!this.worksite) return `/worksite/worksite-closure-reports`;
        return `/worksite/worksite/${this.worksite}/worksite-closure-reports`;
    }

    index(): string {
        return this.base();
    }

    show(id: string): string {
        return `${this.base()}/${id}`;
    }
}
